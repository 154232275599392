<template>
  <div>
    <b-button
      v-b-toggle.basket
      variant="light"
      class="shadow-sm ml-3 border font-weight-bold"
    >
      <b-icon-cart4 scale="1.5" variant="primary" />

      <span class="ml-3">Səbət</span>
      <b-badge variant="success" class="ml-3">{{
        items && items.length
      }}</b-badge>
    </b-button>

    <b-sidebar
      no-header
      @shown="fetchBasketItems"
      backdrop
      ref="basket"
      left
      width="1050px"
      id="basket"
    >
      <div class="m-3">
        <RowCol>
          <b-icon-cart4 class="mr-3" scale="2" variant="primary" />
          <SectionHeader name="Səbət" />
        </RowCol>
        <RowCol class="mb-3">
          <b-form @submit.prevent="handleSubmit" inline>
            <InputText
              class="w-50"
              v-model="computed_note"
              :required="false"
              name="Qeyd:"
            />
            <b-button
              type="submit"
              variant="light"
              class="shadow-sm ml-2 border font-weight-bold text-success"
            >
              Sifarişi Göndər
            </b-button>
            <b-button
              v-if="items && items.length > 0"
              variant="light"
              @click="handleFullDelete"
              class="shadow-sm ml-2 border font-weight-bold text-danger"
            >
              Səbəti Təmizlə
            </b-button>
          </b-form>
        </RowCol>
        <RowCol>
          <b-table
            small
            bordered
            outlined
            hover
            :items="items"
            :fields="fields"
            :busy="disabled"
            class="shadow"
          >
            <template #table-busy>
              <b-spinner variant="dark" class="my-3 align-middle" />
            </template>

            <template #bottom-row>
              <td colspan="4"></td>
              <td class="font-weight-bolder">CƏM:</td>
              <td class="font-weight-bolder">
                {{ total_quantity ? total_quantity : 0 }}
              </td>
              <td></td>
              <td class="font-weight-bolder">
                {{ total ? parseFloat(total).toFixed(2) : 0 }}
              </td>
            </template>
            <template #cell(selected)="data">
              <b-form-checkbox
                class="ml-2"
                :checked="data.item.selected"
                @change="handleSelectedChange(data.item.id, $event)"
              />
            </template>
            <template #head(selected)>
              <b-form-checkbox
                class="ml-2"
                :checked="fullchecked"
                @change="handleFullCheck"
              />
            </template>
            <template #cell(N)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(description)="data">
              {{
                data.item.description && data.item.description.length > 0
                  ? data.item.description.substring(0, 30)
                  : null
              }}
            </template>
            <template #cell(mebleg)="data">
              {{ parseFloat(data.item.quantity * data.item.price).toFixed(2) }}
            </template>
            <template #cell(price)="data">
              {{ parseFloat(data.item.price).toFixed(2) }}
            </template>
            <template #cell(quantity)="data">
              <strong>{{ data.item.quantity }}</strong>
            </template>
            <template #cell(-)="data">
              <b-button
                size="sm"
                variant="light"
                v-b-modal.basket_modal
                class="ml-1 shadow-sm border"
                @click="
                  setProductId(data.item.product_id, data.item.quantity, true)
                "
              >
                <b-icon-pencil-square variant="dark" />
              </b-button>
              <b-button
                size="sm"
                variant="light"
                class="ml-1 shadow-sm border"
                @click="handleDelete(data.item.id)"
              >
                <b-icon-x-circle-fill variant="danger" />
              </b-button>
            </template>
          </b-table>
        </RowCol>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import RowCol from "@/components/RowCol";
import InputText from "@/components/InputText";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "BasketSidebar",
  components: { SectionHeader, InputText, RowCol },
  props: [
    "handleSubmit",
    "items",
    "fields",
    "disabled",
    "note",
    "fetchBasketItems",
    "setProductId",
    "handleDelete",
    "handleSelectedChange",
    "handleFullDelete",
    "fullchecked",
    "handleFullCheck",
  ],
  computed: {
    computed_note: {
      get() {
        return this.note;
      },
      set(val) {
        this.$emit("update:note", val);
      },
    },
    total() {
      let total = 0;
      this.items.map((item) => {
        total += item.quantity * item.price;
      });
      return total;
    },
    total_quantity() {
      let total_quantity = 0;
      this.items.map((item) => {
        total_quantity += item.quantity;
      });
      return total_quantity;
    },
  },
};
</script>
