<template>
  <div>
    <b-modal
      id="basket_modal"
      centered
      size="sm"
      hide-header
      hide-footer
      @close="handleClose"
      @hide="handleClose"
      hide-backdrop
    >
      <div class="text-center">
        <span
          class="d-inline-block text-dark h5
         shadow-sm p-2 font-weight-bolder rounded border"
          >Səbətə Əlavə Et:</span
        >
      </div>
      <hr />
      <div class="text-center">
        <b-button
          variant="light"
          class="ml-2 border font-weight-bold shadow-sm"
          @click="
            computed_quantity === 1
              ? null
              : (computed_quantity = computed_quantity - 1)
          "
          >-
        </b-button>
        <b-button
          variant="light"
          class="mx-4 border font-weight-bold text-primary shadow-sm"
        >
          {{ computed_quantity }}
        </b-button>

        <b-button
          variant="light"
          class="border font-weight-bold shadow-sm"
          @click="computed_quantity = computed_quantity + 1"
          >+
        </b-button>
      </div>
      <hr />
      <div class="text-center">
        <b-button
          variant="light"
          @click="handleClose"
          class="ml-3 font-weight-bold border shadow-sm text-danger"
        >
          Ləğv et
        </b-button>
        <b-button
          variant="light"
          @click="handleOk"
          class="ml-3 font-weight-bold border shadow-sm text-success"
        >
          Təsdiqlə
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "BasketModal",
  props: ["quantity", "handleOk", "handleClose"],
  computed: {
    computed_quantity: {
      get() {
        return this.quantity;
      },
      set(val) {
        this.$emit("update:quantity", val);
      },
    },
  },
};
</script>
