<template>
  <div>
    <Navbar />
    <BasketModal
      :quantity.sync="quantity"
      :handle-close="handleModalClose"
      :handle-ok="handleModalOk"
    />
    <GlobalToast />
    <b-container class="text-center">
      <!--   SEARCH BOX   -->
      <RowCol>
        <b-form
          @submit.prevent="handleSubmit"
          inline
          class="justify-content-center"
        >
          <BasicSelect
            style="width: 300px;"
            name="Axtarış Növü:"
            v-model="selected_search"
            :options="search_types"
          />
          <InputText
            style="width: 400px;"
            placeholder="Kodu daxil edin..."
            v-model="kod"
            :required="true"
            class="ml-3"
          />

          <b-button
            type="submit"
            variant="light"
            :disabled="disabled"
            class="shadow-sm ml-3 border"
          >
            <b-icon-search variant="secondary" />
          </b-button>

          <BasketSidebar
            :items="basket_items"
            :fields="basket_fields"
            :disabled="disabled"
            :note.sync="note"
            :fetch-basket-items="fetchBasketItems"
            :handle-submit="handleSubmitBasket"
            :handle-delete="handleOneDelete"
            :handle-full-check="handleFullCheck"
            :fullchecked="fullchecked"
            :handle-selected-change="handleSelectedChange"
            :set-product-id="setProductId"
          />
          <div
            class="d-inline-block text-danger h5  shadow-sm ml-3 mt-2  p-2 font-weight-bold
                rounded border"
          >
            {{ "Borc: " + parseFloat(borc).toFixed(2) }}
          </div>
        </b-form>
      </RowCol>

      <Messages :messages="messages" />
      <StockTable
        :items="stock_items"
        :fields="stock_fields"
        :disabled="disabled"
        :set-product-id="setProductId"
        :selected_search="selected_search"
      />

      <DubaiTable
        :items="dubai_items"
        :fields="dubai_fields"
        :disabled="disabled"
        :selected_search="selected_search"
      />
      <RussiaTable
        :items="russia_items"
        :fields="russia_fields"
        :disabled="disabled"
        :selected_search="selected_search"
      />
    </b-container>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import RowCol from "@/components/RowCol";
import BasicSelect from "@/components/BasicSelect";
import InputText from "@/components/InputText";
import BasketModal from "@/components/BasketModal";
import Messages from "@/components/Messages";
import GlobalToast from "@/components/GlobalToast";
import StockTable from "@/components/StockTable";
import DubaiTable from "@/components/DubaiTable";
import RussiaTable from "@/components/RussiaTable";
import BasketSidebar from "@/components/BasketSidebar";

export default {
  name: "Stock",
  components: {
    BasketSidebar,
    RussiaTable,
    DubaiTable,
    StockTable,
    GlobalToast,
    Messages,
    BasketModal,
    InputText,
    BasicSelect,
    RowCol,
    Navbar,
  },
  data() {
    return {
      disabled: false,
      borc: 0,
      kod: null,
      product_id: null,
      quantity: 1,
      update: false,
      messages: [],
      stock_items: [],
      dubai_items: [],
      russia_items: [],
      basket_items: [],
      selected_basket_items: [],
      fullchecked: false,
      selectMode: "multi",
      note: "",
      basket_fields: [
        { key: "selected", label: "Seç" },
        "N",
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "quantity", label: "Say" },
        { key: "price", label: "Qiymət (AZN) " },
        { key: "mebleg", label: "Məbləğ (AZN)" },
        "-",
      ],
      stock_fields: [
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "elit", label: "Elit Stok" },
        { key: "cobanzade", label: "Çobanzade Stok" },
        { key: "yolda", label: "Yolda", variant: "secondary" },
        { key: "price", label: "Qiymət (AZN) " },
        "-",
      ],
      dubai_fields: [
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "price", label: "Qiymət (AZN) " },
      ],
      russia_fields: [
        { key: "brand", label: "Brend" },
        { key: "name", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "price", label: "Qiymət (AZN) " },
      ],
      selected_search: "stock",
      search_types: [
        {
          value: "stock",
          text: "Stok Qiymətləri",
        },
        {
          value: "dubai",
          text: "Dubay Qiymətləri",
        },
        // {
        //   value: "russia",
        //   text: "Rusiya Qiymətləri",
        // },
      ],
    };
  },
  created() {
    this.$store.commit("SET_SECTION", "MƏHSULLAR");
    this.fetchMessages();
    this.fetchBasketItems();
    this.fetchBorc();
  },

  methods: {
    fetchBorc() {
      axios.post("/sales_invoices_credit_advance").then((result) => {
        this.borc = result.data.borc;
      });
    },
    handleSubmit() {
      if (this.selected_search === "stock") {
        this.handleStockSearch();
      }
      if (this.selected_search === "dubai") {
        this.handleDubaySearch();
      }
      if (this.selected_search === "russia") {
        this.handleRussiaSearch();
      }
    },
    handleModalClose() {
      this.$bvModal.hide("basket_modal");
    },
    handleModalOk() {
      const data = { quantity: this.quantity, product_id: this.product_id };
      this.$bvModal.hide("basket_modal");

      axios
        .post(this.update ? "/basket/update" : "/basket/add", data)
        .then((result) => {
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchBasketItems();
        });
    },
    handleStockSearch() {
      const kod = this.kod
        ? this.kod
            .split("-")
            .join("")
            .trim()
        : "";
      this.disabled = true;

      axios.post("/stock", { kod }).then((result) => {
        this.disabled = false;
        if (result.data.success) {
          this.stock_items = result.data.results;
        } else {
          this.stock_items = [];
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
        }
      });
    },
    handleDubaySearch() {
      const kod = this.kod
        ? this.kod
            .split("-")
            .join("")
            .trim()
        : "";
      this.disabled = true;
      axios.post("/dubai", { kod }).then((result) => {
        this.disabled = false;
        if (result.data.success) {
          this.dubai_items = result.data.results;
        } else {
          this.dubai_items = [];
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
        }
      });
    },
    handleRussiaSearch() {
      const kod = this.kod
        ? this.kod
            .split("-")
            .join("")
            .trim()
        : "";
      this.disabled = true;
      axios.post("/russia", { kod }).then((result) => {
        this.disabled = false;
        if (result.data.success) {
          this.russia_items = result.data.results;
        } else {
          this.russia_items = [];
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
        }
      });
    },
    setProductId(product_id, quantity, update) {
      this.product_id = product_id;
      this.quantity = quantity;
      this.update = update;
    },
    fetchMessages() {
      axios.post("/stock/messages").then((result) => {
        this.messages = result.data.messages;
      });
    },
    fetchBasketItems() {
      this.disabled = true;
      this.fullchecked = false;
      axios.get("/basket/index").then((result) => {
        // TODO:check fullchecked here manually
        this.basket_items = result.data.results;
        this.selected_basket_items = this.basket_items;
        for (let item of this.basket_items) {
          if (item.selected) {
            this.fullchecked = true;
            break;
          }
          this.fullchecked = false;
        }
        this.disabled = false;
      });
    },
    handleFullCheck() {
      // i need to send the selected option to server here
      this.full_checked = !this.full_checked;
      const data = { selected: this.full_checked };
      axios.post("/basket/full/check", data).then((result) => {
        this.$store.commit("SET_GLOBAL_TOAST", result.data);
        this.fetchBasketItems();
      });
    },

    handleFullDelete() {
      if (confirm("ƏMİNSƏN?")) {
        axios.post("/basket/full/delete").then((result) => {
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchBasketItems();
        });
      }
    },
    handleOneDelete(id) {
      if (confirm("ƏMİNSƏN?")) {
        const data = { detail_id: id };
        axios.post("/basket/one/delete", data).then((result) => {
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchBasketItems();
        });
      }
    },
    handleSelectedChange(id, event) {
      const data = { detail_id: id, selected: event };
      axios.post("/basket/toggle_selected_one", data).then((result) => {
        this.$store.commit("SET_GLOBAL_TOAST", result.data);
        this.fetchBasketItems();
      });
    },
    handleSubmitBasket() {
      if (confirm("ƏMİNSƏN?")) {
        const data = { note: this.note };
        this.disabled = true;
        axios.post("/basket/approve", data).then((result) => {
          this.disabled = false;
          this.note = "";
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchBasketItems();
        });
      }
    },
  },
};
</script>
