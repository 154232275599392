<template>
  <RowCol v-if="selected_search === 'stock'">
    <b-spinner v-if="disabled" class="mt-4" />
    <div v-if="!disabled" class="mt-4">
      <b-table
        v-if="items && items.length > 0"
        bordered
        hover
        small
        class="shadow"
        :items="items"
        :fields="fields"
      >
        <template #cell(description)="data">
          {{
            data.item.description && data.item.description.length > 0
              ? data.item.description.substring(0, 30)
              : null
          }}
        </template>
        <template #cell(price)="data">
          {{ data.item.quantity === 0 ? 0 : data.item.price }}
        </template>
        <template #cell(quantity)="data">
          <span class="font-weight-bold"> {{ data.item.quantity }}</span>
        </template>
        <template #cell(-)="data">
          <b-button
            size="sm"
            v-b-modal.basket_modal
            variant="light"
            class="border shadow-sm"
            @click="setProductId(data.item.id, 1, false)"
          >
            <b-icon-cart4 scale="1.2" />
          </b-button>
        </template>
      </b-table>
    </div>
  </RowCol>
</template>
<script>
import RowCol from "@/components/RowCol";

export default {
  name: "StockTable",
  components: { RowCol },
  props: ["items", "fields", "disabled", "selected_search", "setProductId"],
};
</script>
