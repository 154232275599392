<template>
  <RowCol v-if="selected_search==='russia'">
    <b-spinner v-if="disabled" class="mt-4"/>
    <div v-if="!disabled" class="mt-4">
      <p class="text-danger">Rusiya sifarişlərini 15 gün ərzində Bakıda təhvil verə bilərik.</p>

      <b-table
          v-if="items && items.length>0"
          bordered
          hover
          small
          class="shadow"
          :items="items"
          :fields="fields">
        <template #cell(name)=data>
          {{
            data.item.name && data.item.name.length > 0
                ? data.item.name.substring(0, 50) : null
          }}
        </template>
      </b-table>
    </div>
  </RowCol>
</template>
<script>

import RowCol from "@/components/RowCol";

export default {
  name: "RussiaTable",
  components: {RowCol,},
  props: ["items", "fields", "disabled", "selected_search"]

};
</script>