<template>
  <RowCol v-if="selected_search === 'dubai'">
    <b-spinner v-if="disabled" class="mt-4" />
    <div v-if="!disabled" class="mt-4">
      <p class="text-danger">
        Dubay sifarişləri bazar günləri göndərilir. 15-20 gün ərzində sifarişi
        Bakıda təhvil verə bilərik. Padpres sifarişinə 150 manat əlavə pul
        gəlir.
      </p>

      <b-table
        v-if="items && items.length > 0"
        bordered
        hover
        small
        class="shadow"
        :items="items"
        :fields="fields"
      >
        <template #cell(price)="data">
          <template v-if="data.item.weight === 0">
            0
          </template>
          <template v-else>
            {{ data.item.price }}
          </template>
        </template>
      </b-table>
    </div>
  </RowCol>
</template>
<script>
import RowCol from "@/components/RowCol";

export default {
  name: "DubaiTable",
  components: { RowCol },
  props: ["items", "fields", "disabled", "selected_search"],
};
</script>
