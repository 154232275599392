<template>
  <b-input-group class="rounded shadow-sm">
    <b-input-group-prepend v-if="name">
      <div
        class="pt-1 px-2 bg-light border
          font-weight-bold text-dark rounded-left"
      >
        {{ name }}
      </div>
    </b-input-group-prepend>
    <b-form-select
      class="border"
      :required="!!required"
      v-model="my_value"
      :options="options"
    />
  </b-input-group>
</template>

<script>
export default {
  name: "BasicSelect",
  props: ["name", "value", "options", "required"],
  computed: {
    my_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
